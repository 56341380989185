@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
  .timeline {
    @apply
      border-l-2 border-black
      ml-3 mt-3 py-16
      space-y-14
  }

  .dot {
    @apply
      absolute top-5 -left-3.5
      bg-green-300
      h-6 w-6
      rounded-full
      border-4 border-white
  }

  .timeline-date {
    @apply
      text-xl font-bold opacity-40
  }

  .timeline-title {
    @apply
      text-3xl font-bold
      tracking-wide mb-2
  }

  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
